import Splide from '@splidejs/splide';

let highlightWithLinkSliderComponent = function (element) {
  let self = {
    scrollBarWidth: 0,
    centerMaxWidth: 1920,
    slider: element,

    initMobileSlider: function () {
      let mobileSlider = self.slider.querySelector('.highlightWithLink__wrapper--infinite-slider');

      new Splide(mobileSlider, {
        type: 'loop',
        start: 1,
        focus: 'center',
        autoWidth: true,
        arrows: false,
        pagination: false,
      }).mount();
    },

    initArrows: function () {
      // Set current position and hide left or right arrow buttons
      if (self.slider.classList.contains('highlight-slider--left')) {
        self.slider.setAttribute('data-current-position', 0);

        let leftArrowButton = self.slider.querySelector('.highlight-slider__arrow--nav-left');
        leftArrowButton.style.display = 'none';

        self.setSliderNavEvents(self.slider);
      } else if (self.slider.classList.contains('highlight-slider--right')) {
        let num =
          self.slider.querySelectorAll('.highlightWithLink__wrapper--normal-slider .highlightWithLinkItem').length - 1;
        self.slider.setAttribute('data-current-position', num);

        let rightArrowButton = self.slider.querySelector('.highlight-slider__arrow--nav-right');
        rightArrowButton.style.display = 'none';
        self.setSliderNavEvents(self.slider);
      }
    },

    setSliderNavEvents: function (slider) {
      let leftArrowButton = slider.querySelector('.highlight-slider__arrow--nav-left');
      leftArrowButton.addEventListener('click', function (event) {
        self.handleArrowClick(-1, event);
      });

      let rightArrowButton = slider.querySelector('.highlight-slider__arrow--nav-right');

      rightArrowButton.addEventListener('click', (event) => {
        self.handleArrowClick(1, event);
      });
    },

    handleArrowClick: function (moveNumberOfSteps, event, specificPosition) {
      let slider = event.target.closest('.highlight-slider');
      let sliderWrapper = slider.querySelector('.highlightWithLink__wrapper.highlightWithLink__wrapper--normal-slider');
      let numberOfItems = sliderWrapper.querySelectorAll('.highlightWithLinkItem').length;

      let currentPosition = parseInt(slider.getAttribute('data-current-position'), 10);
      let position = specificPosition !== undefined ? specificPosition : currentPosition + moveNumberOfSteps;

      let leftArrowButton = slider.querySelector('.highlight-slider__arrow--nav-left');
      let rightArrowButton = slider.querySelector('.highlight-slider__arrow--nav-right');

      if (position === 0) {
        leftArrowButton.style.display = 'none';
      } else {
        leftArrowButton.style.display = 'flex';
      }

      if (position == numberOfItems - 1) {
        rightArrowButton.style.display = 'none';
      } else {
        rightArrowButton.style.display = 'flex';
      }

      let maxScroll = sliderWrapper.scrollWidth - sliderWrapper.clientWidth;
      let scrollX = (maxScroll / (numberOfItems - 1)) * position;

      slider.setAttribute('data-current-position', position);
      sliderWrapper.scrollTo({ left: scrollX, top: 0, behavior: 'smooth' });
    },

    setMarginWidths: function () {
      const fillWidth = window.innerWidth / 12;
      const hiResFillWidth = self.centerMaxWidth / 12 - 15;

      const marginElements = self.slider.querySelectorAll(
        '.highlightWithLink__wrapper .highlight-slider__left-margin-fill, .highlight-slider .highlightWithLink__wrapper .highlight-slider__right-margin-fill'
      );

      if (marginElements != null) {
        marginElements.forEach((element) => {
          if (window.innerWidth > self.centerMaxWidth) {
            // HI-RES
            element.style.paddingLeft = hiResFillWidth + 'px';
          } else {
            element.style.paddingLeft = fillWidth - 16 + 'px';
          }
        });
      }
    },

    // Scroll right sliders (sliders that start from right ) to left.
    setAlignments: function () {
      if (self.slider.classList.contains('highlight-slider--right')) {
        let wrapper = self.slider.querySelector('.highlightWithLink__wrapper--normal-slider');
        wrapper.scrollLeft = wrapper.scrollWidth;
      }
    },

    setItemNumbers: function () {
      let sliderWrappers = self.slider.querySelectorAll('.highlightWithLink__wrapper');

      if (sliderWrappers != null) {
        sliderWrappers.forEach((wrapper) => {
          let items = wrapper.querySelectorAll('.highlightWithLinkItem');

          items.forEach((item, index) => {
            item.classList.add('highlight-slider-item--num-' + index);
            item.setAttribute('data-num', index);
          });
        });
      }
    },

    // Handles calling arrowClick when using tab to navigate slides in order to scroll focused slide into view
    setLinkFocus: function () {
      let links = self.slider.querySelectorAll('.highlightWithLinkItem__link');

      links.forEach((link) => {
        link.addEventListener('focus', function (event) {
          let obj = event.target;
          let num = obj.closest('.highlightWithLinkItem').getAttribute('data-num');

          self.handleArrowClick(0, { target: obj }, num);
        });
      });
    },

    copyDesktopSliderItemsToMobileSlider: function () {
      let desktopSliderWrapper = self.slider.querySelector('.highlightWithLink__wrapper--normal-slider');

      let mobileSlider = self.slider.querySelector('.highlightWithLink__wrapper--infinite-slider .splide__list');

      let wrapperItems = desktopSliderWrapper.querySelectorAll('.highlightWithLinkItem');
      if (wrapperItems != null) {
        wrapperItems.forEach((item) => {
          let clonedItem = item.cloneNode(true);
          clonedItem.classList.add('splide__slide');
          clonedItem.classList.add('highlightWithLinkItem-no-decoration');
          mobileSlider.appendChild(clonedItem);
        });
      }
    },

    init: function () {
      if (_THULEDATA.IsEditing) {
        return;
      }
      self.copyDesktopSliderItemsToMobileSlider();
      self.initMobileSlider();
      self.setItemNumbers();
      self.setMarginWidths();
      self.setAlignments();
      self.setLinkFocus();
      self.initArrows();
    },
  };

  return self;
};

let highlightWithLinkNoSliderComponent = function (component) {
  let self = {
    setHandles: function () {
      let wrapper = component.querySelector('.highlight-no-slider__wrapper');
      let cols = parseInt(wrapper.getAttribute('data-cols'));

      let items = wrapper.querySelectorAll('.highlightWithLinkItem');

      if (items != null) {
        let numOfItems = items.length;
        let rest = numOfItems % cols;

        if (cols === 2) {
          items.forEach((element, i) => {
            if (i % 2 !== 0) {
              element.classList.add('justify-content-start');
              element.classList.remove('justify-content-center');
            }
            if (i % 2 === 0 && i !== numOfItems - 1) {
              element.classList.add('justify-content-end');
              element.classList.remove('justify-content-center');
            }
          });
        }

        for (let i = 0; i < numOfItems - rest; i++) {
          if (i % cols === 0) {
            //First item in row
            items[i].classList.add('highlightWithLinkItem--remove-after');
          } else if ((i + 1) % cols === 0) {
            //Last item in a row
            items[i].classList.add('highlightWithLinkItem--remove-before');
          } else {
            // All other items
            items[i].classList.add('highlightWithLinkItem--remove-before');
            items[i].classList.add('highlightWithLinkItem--remove-after');
          }
        }

        if (rest > 0) {
          for (let i = numOfItems - rest; i < numOfItems; i++) {
            if (i < numOfItems - 1) {
              items[i].classList.add('highlightWithLinkItem--remove-after');
            }
            if (i > numOfItems - rest) {
              items[i].classList.add('highlightWithLinkItem--remove-before');
            }
          }
        }
      }
    },

    init: function () {
      if (_THULEDATA.IsEditing) {
        return;
      }
      self.setHandles();
    },
  };

  return self;
};

document.addEventListener('DOMContentLoaded', function () {
  let sliders = document.querySelectorAll('.highlightWithLink.highlight-slider');

  if (sliders != null) {
    sliders.forEach((slider) => {
      let highlightSliderComponent = new highlightWithLinkSliderComponent(slider);
      highlightSliderComponent.init();
    });
  }

  let noSliderComponents = document.querySelectorAll('.highlightWithLink.highlight-no-slider');

  if (noSliderComponents != null) {
    noSliderComponents.forEach((component) => {
      let noSliderComponents = new highlightWithLinkNoSliderComponent(component);
      noSliderComponents.init();
    });
  }
});
